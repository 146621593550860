import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout_page from "../../components/layout_page"
import { Link } from "gatsby"
import { useState } from "react"

// needed to use font awsome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  // if the page is marked as hidden in the markdwon file
  if (frontmatter.hidden) {

    return (
      <p className="">Denna sidan är dold. <Link to="/" className="text-blue-600">Gå till startsidan</Link></p>
    );

  } else {
    return (

      <>

        <Layout_page>

          <section className="px-8">
            <div className="flex flex-col items-center w-full max-w-[65ch] mb-8 pb-4">
              <h1 className="mt-28 text-center text-3xl font-medium text-def_yellow_2">{frontmatter.title}</h1>
              <span className="text-neutral-400 font-light mt-4"><FontAwesomeIcon className="pr-2" icon={solid('calendar-day')} />Uppdaterad {frontmatter.date_updated}</span>
              <div className="mt-4">
                <p className="text-sm inline-block px-2 py-1 rounded-full text-white bg-neutral-700 border-2 border-neutral-500">{frontmatter.category}</p>
              </div>
            </div>
          </section>


          <article className="prose prose-invert mx-8">
            <div className="blog-post-content max-w-xs sm:max-w-sm md:max-w-full" dangerouslySetInnerHTML={{ __html: html }} />
          </article>

        </Layout_page>
      </>


    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        hidden
        date_updated(formatString: "DD MMMM, YYYY", locale: "sv-SE")
        category
      }
    }
  }
`
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => <Seo title={data.markdownRemark.frontmatter.title} />